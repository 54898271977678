import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { ContactFormIndex } from '../forms/ContactFormIndex'

const HomePageTemplate = (props) => {
  return (
    <div>
      <Helmet>
        <title>Nimber Inc</title>
        <meta name='description' content='Nimber Inc' />
      </Helmet>
      <section className='hero is-bold is-medium background-index-1'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/index-1.png' />
              </div>
              <div className='column'>
                <img src='/img/mock-desktop.png' width='585' height='472'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-small'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column'>
                <div className='section'>
                  <img src='/img/index-2-1.png' width='405' height='279'/>
                </div>
              </div>
              <div className='column'>
                <div className='section'>
                  <img src='/img/index-2-2.png' width='405' height='279'/>
                </div>
              </div>
              <div className='column'>
                <div className='section'>
                  <img src='/img/index-2-3.png' width='405' height='279'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-small background-index-2'>
        <img src='/img/partner-organizations.png' />
      </section>
      <section className='hero is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-5 is-offset-1'>
                <Link to='/businesses'>
                  <img src='/img/index-businesses.png' width='480' height='565' />
                </Link>
              </div>
              <div className='column is-5 '>
                <Link to='/guests'>
                  <img src='/img/index-guests.png' width='480' height='565' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='hero is-bold is-medium is-primary'>
        <div className='hero-body'>
          <div className='container'>
            <h1 className='title has-text-centered'> We'd love to hear from you</h1>
            <span />
            <h2 className='subtitle has-text-centered'> Our team is ready to answer any questions you have.</h2>
            <span />
          </div>
          <div className='container'>
            <ContactFormIndex />
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomePageTemplate
