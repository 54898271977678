import React from 'react'
import { Formik, Field } from 'formik'
import { navigate } from 'gatsby-link'
import validationSchema from '../validationSchema'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactFormIndex = () => {
  return (
    <Formik
      initialValues={{ name: '', email: '', message: '', phoneNumber: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        fetch("/?no-cache=1", {                                 //eslint-disable-line
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            ...values,
          }),
        })
          .then(() => {
            navigate('/contact/success')
            setSubmitting(false)
          })
          .catch(error => {
            console.log(error)
            alert("Error: Please Try Again!");                            //eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (<form
        name='contact'
        onSubmit={handleSubmit}
        onReset={handleReset}
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <div className='columns is-vcentered'>
          <div className='column is-one-third is-offset-2 '>
            <div className='field'>
              <div className='control'>
                <Field className='input' type='text' placeholder='Name' name='name' id='name' />
              </div>
              {touched.name && errors.name && <small className='has-text-danger'>{errors.name}</small>}
            </div>
            <div className='field'>
              <div className='control'>
                <Field className='input' type='email' placeholder='Email Address' name='email' id='email' />
              </div>
              {touched.email && errors.email && <small className='has-text-danger'>{errors.email}</small>}
            </div>
            <div className='field'>
              <div className='control'>
                <Field className='input' placeholder='Phone Number' name='phoneNumber' id='phoneNumber' />
              </div>
              {touched.phoneNumber && errors.phoneNumber && <small className='has-text-danger'>{errors.phoneNumber}</small>}
            </div>
          </div>
          <div className='column is-one-third'>
            <div className='field'>
              <div className='control'>
                <Field className='textarea' component='textarea' placeholder='Message' name='message' id='message' rows='6' />
              </div>
              {touched.message && errors.message && <small className='has-text-danger'>{errors.message}</small>}
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-one-third is-offset-2 '>
          </div>
          <div className='column is-one-third '>
            <div className='field is-grouped is-pulled-right'>
              <div className='control'>
                <button className='button' type='reset'>Clear</button>
              </div>
              <div className='control'>
                <button className='button' type='submit' disabled={isSubmitting}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>)}
    />
  )
}

export { ContactFormIndex }
